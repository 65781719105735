.paraTag{
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 14px;
    line-height: 15.62px;
    color: #757575;
}

.borderbottom{
    border-bottom: 1px solid #E6E6E6;
    /* height: 1px; */
    color: #E6E6E6;
    margin-top: -21px!important;
    width: 601px;
}
.error-word{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: -25px;
    font-weight: 700;
    font-family: 'DM Sans';
    font-size: 14px;
    line-height: 18.23px;
    color: #DC3544;
}
.error-para{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 9px;
    margin-bottom: 22px;
    font-weight: 500;
    font-family: 'DM Sans';
    font-size: 14px;
    line-height: 18.23px;
    color: #DC3544;
}
.error-para a:hover{
    text-decoration: underline;
}

/* Styles for screens with a height between 600px and 900px */
@media (min-height: 1000px) and (max-height: 1100px) {
    /* Add your styles here */
    .blackbox {
        height: 1621px;
    }
}

@media (min-height: 1100px) and (max-height: 1500px) {
    /* Add your styles here */
    .blackbox {
        height: 1621px;
    }
}

@media (min-height: 1500px) and (max-height: 1700px) {
    /* Add your styles here */
    .blackbox {
        height: 1800px;
    }
}

@media (min-height: 1700px) and (max-height: 2000px) {
    /* Add your styles here */
    .blackbox {
        height: 2000px;
    }
}

@media (min-height: 900px) and (max-height: 1000px) {
    /* Add your styles here */
    .blackbox {
        height: 1621px;
    }
}

@media (min-height: 600px) and (max-height: 900px) {
    /* Add your styles here */
    .blackbox {
        height: 956px;
    }
}