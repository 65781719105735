figure {
    margin: 0;
    padding: 0;
}

img {
    display: block;
}

.inside-table-cont {
    font-family: 'DM Sans', sans-serif;
    background: #F8FAFF;
    color: #757575;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    border-radius: 10px;
    padding: 20px;
}

.discriptionarea{
    width: 1024px;
}

@media  screen and (min-width: 1200px) {
    .discriptionarea{
        width: auto;
    }
}

.inside-table-main {
    border-radius: 10px;
}

figure.in-author-img {
    width: 50px;
    margin-right: 12px;
}

figure.inside-author-img img {
    width: 50px;
    max-width: 50px;
    height: 50px;
    max-height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.in-author-display {
    display: flex;
    align-items: center;
}

.list-position {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #757575;
    margin-top: 4px;
}

.in-author-name a {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
    color: #1A1A1A;
    text-decoration: underline;
}

.in-author-name a:hover {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
}

.inside-icon {
    margin-right: 10px;
}

.inside-icon-1 {
    position: relative;
    top: 2px;
    margin-left: 10px;
    display: inline-block;
}

.in-like-dislike {
    display: flex;
    margin: 5px 0;
    justify-content: center;
    align-items: center;
}

.in-link a {
    color: #757575;
    text-decoration: none;
    border-bottom: 1px solid rgba(117, 117, 117, 1);
    transition: all 0.5s;
}

.in-link a:hover {
    border-bottom: 1px solid rgba(117, 117, 117, 0);
}

/* .inside-table-data {
    overflow-x: auto;
} */

.inside-table-data table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.inside-table-data table tr:not(:last-child) td {
    border-bottom: 1px solid #E6E6E6;
    padding: 10px;
}

.inside-table-data table tr:last-child td {
    border-bottom: none;
    padding: 10px;
}

.inside-table-data table td.in-list-name {
    padding-left: 35px;
}

.in-loader .in-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #E6EAF5;
    margin-right: 10px;
}

.in-loader .in-loader-line {
    height: 5px;
    width: 80%;
    background: #E6EAF5;
    border-radius: 2px;
    margin: 0 auto;
}

.in-loader .in-list1 .in-loader-line {
    width: 63%;
    margin: 0;
}

.in-loader td {
    width: 20%;
}

/* .inside-table-data table td.in-list-name {
    padding-left: 35px;
} */

.like-dislike {
    width: 15%;
    text-align: center;
}

/* .like-dislike .like-btn path:hover,
.like-dislike .like-btn.active path {
    fill: #3AB795;
} */

/* .like-dislike .dislike-btn path:hover,
.like-dislike .dislike-btn.active path {
    fill: #DC3544;
} */

.like-no,
.dislike-no {
    position: relative;
}

.like-no {
    top: 2px;
    padding-right: 7px;
}

.dislike-no {
    top: -5px;
    padding-right: 8px;
}

.like-no.active {
    color: #3AB795;
}

.dislike-no.active {
    color: #DC3544;
}

/* start accordion style */
.list-accordion__item {
    margin: 0 0 20px;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
}

.list-accordion__item .list-accordion__title {
    position: relative;
    display: block;
    padding: 10px 20px 10px;
    margin-bottom: 2px;
    color: #1a1a1a;
    font-size: 20px;
    line-height: 1.3;
    text-decoration: none;
    cursor: pointer;
}

.list-accordion__title-text {
    display: flex;
    align-items: center;
}

.list-accordion__item .list-accordion__title .acc-list-no {
    width: 28px;
    height: 28px;
    color: #fff;
    background-color: #008CF0;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    margin-right: 10px;
    border-radius: 50%;
    padding: 1px;
}

.list-accordion__item .list-accordion__title:hover {
    transition: all 0.5s ease-out;
}

.list-accordion__item .list-accordion__title .list-accordion__arrow {
    position: absolute;
    top: 20px;
    left: 300px;
    display: inline-block;
}

.list-accordion__item .list-accordion__title .list-accordion__arrow .arrow {
    border: solid #1A1A1A;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.list-accordion__arrow.list-accordion__rotate .arrow {
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
}

.list-accordion__item .list-accordion__content {
    display: none;
}

.list-accordion__item .list-accordion__arrow-item {
    font-weight: 700;
}

.list-accordion-active+.list-accordion__content {
    display: block;
}

/* end accordion style */
/* start tooltip style */
.has-tooltip {
    /*position: relative;*/
    display: inline;
}

.tooltip-wrapper {
    position: absolute;
    visibility: hidden;
    width: 90px;
}

.has-tooltip:hover .tooltip-wrapper {
    visibility: visible;
}

.tooltipDefault{
    display: block;
    position: relative;
    bottom: 125px;
    right: 91%;
    width: 170px;
    height: 95px;
    max-height: 95px;
    background-color: #757575;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
    text-align: center;
    padding: 18px 24px 18px 24px;
    border-radius: 12px;
    box-sizing: border-box;
}

.tooltipDefault:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 47%;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: #757575 transparent transparent transparent;
}

.tooltipdislike{
    display: block;
    position: relative;
    bottom: 125px;
    right: 91%;
    width: 170px;
    height: 95px;
    max-height: 95px;
    background-color: #DC3544;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
    text-align: center;
    padding: 18px 24px 18px 24px;
    border-radius: 12px;
    box-sizing: border-box;
}

.tooltipdislike:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 47%;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: #DC3544 transparent transparent transparent;
}

.tooltip {
    display: block;
    position: relative;
    bottom: 125px;
    right: 91%;
    width: 170px;
    height: 95px;
    max-height: 95px;
    background-color: #3AB795;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
    text-align: center;
    padding: 18px 24px 18px 24px;
    border-radius: 12px;
    box-sizing: border-box;
}

.tooltip:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 47%;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: #3AB795 transparent transparent transparent;
}

/* end tooltip style */
/* start dropdown style */
.list-subsec {
    display: none;
    position: absolute;
    top: 45px;
    left: 50%;
    width: 190px;
    background-color: #fff;
    border: 0.5px solid #EEEEEE;
    border-radius: 4px;
    box-shadow: 0px 4px 9px 4px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
    z-index: 9;
}

.tooltip-conntent {
    height: 62px;
    overflow-y: auto;
    display: block;
  }

.list-subsec ul {
    list-style-type: none;
}

.list-subsec ul li a {
    display: block;
    color: #757575;
    background: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    display: block;
    transition: all 0.5s;
    padding: 6px 10px;
    text-decoration: none;
}

.list-subsec ul li a.list-sebsec-active {
    color: #000;
    ;
}

.list-subsec ul li a:hover {
    background: #F3F5FA;
}

.list-interview {
    padding: 8px 10px;
    position: relative;
    border-radius: 8px;
    width: 236px;
    transition: all 0.5s;
}

.list-interview:hover {
    background: #F6F6F6;
    box-shadow: 0px 2px 3.5px 2px rgba(0, 0, 0, .1) inset;
}

.list-interview a.list-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #757575;
    position: relative;
}

.list-interview a.list-links span.link-cont:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 10px;
    border: solid #757575;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.list-interview a.list-links span.link-cont {
    position: relative;
    padding-right: 50px;
  }

/* .list-interview.active a.list-links:after {
  top:10px;
  transform: rotate(-135deg)!important;
  -webkit-transform: rotate(-135deg)!important;
} */
/* end dropdown style */

@media  screen and (min-width: 1100px) {
    .in-list-name{
        max-width: 263px;
    }
}

/* start responsive style */
@media screen and (max-width: 767px) {
    .inside-table-cont {
      font-size: 15px;
    }
    .inside-table-data {
      overflow: inherit;
    }
    .inside-table-data table td {
      width: 100%;
      display: block;
      box-sizing: border-box;
    }
    .inside-table-data table td,
    .inside-table-data table td.in-list-name {
      padding-left: 20px;
      padding-right: 20px;
    }
    .inside-table-data table tr {
      border-bottom: 4px solid #E6E6E6;
    }
    .list-interview a.list-links,
    .in-author-display {
      justify-content: center;
    }
    .in-author-name a {
      font-size: 16px;
    }
    .list-position,
    .list-subsec ul li a {
      font-size: 15px;
    }
    .list-position {
      line-height: 1.2;
    }
    .list-accordion__item .list-accordion__title {
      font-size: 17px;
    }
    .list-accordion__item .list-accordion__title .list-accordion__arrow {
      left: auto;
      top: 9px;
      right: 20px;
    }
  }
  /* end responsive style */