.user-icon {
    margin-right: -20px;
}

#can-0{
    /* box-shadow: 1px 1px 14px 0px #0000000D; */
    stroke: #0000000D;
}

.name-text:hover{
  text-decoration: underline;
}

.col100{
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    margin-left: 20px;
    box-sizing: border-box;
    background: #fff;
}
