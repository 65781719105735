/* Hide the default scrollbar in Webkit-based browsers for the aside element */
/* .custom-scroll::-webkit-scrollbar {
    width:1px!important;
    background: transparent;   
} */
.custom-scroll::-webkit-scrollbar-thumb {
    background: transparent;

    transition:all 0.5s;

  }
  
  /* Define the style for the thumb of the scrollbar */
  .custom-scroll:hover::-webkit-scrollbar-thumb {
    background-color: #E6E9F0; /* Color of the thumb */
    border-radius: 6px; /* Rounded corners */
}
  
  /* Define the style for the track of the scrollbar */
  .custom-scroll::-webkit-scrollbar-track {
    background: transparent /* Color of the track */
  }
  
  /* Apply the custom scrollbar styles to the form element */
  /* .custom-scroll:hover {
    scrollbar-width: thin;
    scrollbar-color: #E6E9F0 rgba(0, 0, 0, 0.1); 
  } */
  /* Color of the thumb and track */