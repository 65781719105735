.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  ._loading_overlay_content {
    justify-content: center;
    text-align: center;
    display: block;
    /* margin-top: 300px!important; */
    align-items: center;
    top: 50%;
  }

  .fontSize{
    font-weight: 600;
    font-size: 28px;
  }

  .fontSizezzz{
    line-height: 15.62px;
    font-weight: 600;
    font-size: 18px;
    text-decoration: underline;
    width: max-content;
  }

  .mainCharDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }
  .char_text{
    font-weight: 500;
    font-size: 17px;
    line-height: 0.32px;
    margin-bottom: 15px;
    color: #6936FF;
  }
  .tableMrgin{
    margin-top: 6px;
  }
  .char_count{
    font-weight: 500;
    font-size: 13px;
    line-height: 14.32px;
    color: #424D6A;
  }

  .box-status{
    display: grid;
    justify-content: left;
    padding-left: 35px;
    padding-top: 30px;
    margin-top: -18px;
    align-items: center;
    max-width: 601px;
    background-color: #FFFFFF;
  }

  .upperLine{
    display: grid;
    justify-content: center;
    align-items: center!important;
    max-width: 601px;
    height: 30px;
    background-color: #6936FF;
    border-radius:5px 5px 0px 0px;
  }

  /* .upperLine:first-child{
    background-color: #ffff;
  } */


  .buttonLeft{
    /* margin-left: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .truncate-location {
    overflow: visible;
    text-overflow: ellipsis;
    font-size: 0.872rem;

    white-space: nowrap;
}

.descrip{font-size: 0.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 64px;
  /* text-overflow: hidden; */
}


.descrip-bottom{
    padding: 15px 8px 0px;
    flex-basis: 50%;
    flex-basis: 50%;}

.descrip-bottomm{
  padding: 15px 20px 0px;
  flex-basis: 50%;
  flex-basis: 50%;
}    