.contents {
    flex-grow: 1;
    padding: 0 20px; /* Added padding for content area */
    width: 100%;
    /* max-width: 799px; */
  }

.content-header{
    background: #0496ff !important;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%) !important;
    color: #fff !important;
    padding: 20px 20px 25px !important;
    position: relative !important;
}  

.closePopups {
    display: inline-flex;
    content: "";
    background-image: url(./image/closeIcon.svg);
    height: 40px;
    width: 40px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  
  .closePopups:hover {
    color: red;
  }