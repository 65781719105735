
figure {
    margin:0;
    padding:0; 
}
img {
    display: block;
}

/* Copy from here */
.modal-main {
  max-width: 732px!important;
  background: #fff!important;
  font-family: 'DM Sans', sans-serif!important;
  /* margin:20px auto!important; */
  border-radius: 5px!important;
  -webkit-border-radius: 5px!important;
  /* box-shadow: 1px 1px 4px 3px rgba(0,0,0,0.1)!important; */
}
.modal-main .modal-list-body {
  padding:25px 40px!important;
}
.modal-main .modal-header {
  position: relative!important;
  background: #0496FF!important;
  padding:20px 20px 25px!important;
  border-top-left-radius: 5px!important;
  -webkit-border-top-left-radius: 5px!important;
  border-top-right-radius: 5px!important;
  -webkit-border-top-right-radius: 5px!important;
  color: #fff!important;
  text-align: center!important;
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%)!important;
}
.modal-main .modal-header h2 {
  font-size: 30px!important;
  line-height: 1.1!important;
  font-weight: 600!important;
}
.modal-main .modal-header a {
  position:absolute!important;
  top:23px!important;
  right:25px!important;
  width: 16px!important;
  height: 16px!important;
  display: block!important;
  background: url(images/modal-close-icon.svg) no-repeat 0 0!important;
  font-size: 0!important;
  line-height: 0!important;
  overflow: hidden!important;
}
.modal-list-name {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 40px;
}
.modal-list-name figure {
  width: 79px;
}
.modal-list-name figure img {
  width: 79px;
  max-width: 79px;
  height: 79px;
  max-height: 79px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  object-fit: cover;
  object-position: top;
}
.modal-list-name h3 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
  color:#1a1a1a;
  margin:0;
}
.modal-list-name h4 {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  color: #535353;
  margin:0;
}
.modal-main .modal-close {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  border: 1px solid #EDEDED;
  background: rgba(248,250,255,1);
  color: #434343;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  padding:10px 22px;
  cursor: pointer;
  transition: all 0.5s;
}
.modal-main .modal-close:hover {
  background: rgba(248,250,255,0.8);
}
.right-align {
  text-align: right;
}
.modal-list-data {
  background: #F8FAFF;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-bottom: 25px;
  padding:15px 0;
}
.modal-list-data .select-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0 20px 15px;
}
.modal-list-data .select-list p {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 700;
  color: #757575;
  text-align: center;
}
.modal-list-data .select-list button {
  font-size: 16px;
  line-height: 1.1;
  font-weight: 500;
  border: 1px solid #EDEDED;
  background-color: transparent;
  color: #434343;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  padding:10px 19px;
  cursor: pointer;        
}
.modal-list-data .select-list button:hover {
  border-color: #757575;
  transform: all 0.5s;
}

.select-list .modal-search {
  background: url(images/modal-search-icon.svg) no-repeat 10px 10px;
  width: 173px;
  height: 39px;
  border: 1px solid #E6E9F0;
  background-color: #fff;
  padding:0 10px 0 35px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  box-sizing: border-box;
}
.select-list .modal-search input {
  width: 100%;
  height: 39px;
  border: none;
  background-color: transparent;
  color: #434343;          
  color: #424D6A;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  box-shadow: none;
}
.modal-list-data .select-list-data-cont {
  padding:0 15px 0 20px;
} 
.modal-list-data .select-list-data {
  height: 204px;          
  overflow-y: auto;
}
.modal-list-data .select-list-data table {
  width: 100%;
  background-color: #fff;
}
.modal-list-data .select-list-data table td {
  padding: 10px 20px;
  border-bottom: 1px solid #E6E6E6;
}
.modal-list-data .select-list-data table tr:last-child td {
  border-bottom: none;
}
.modal-list-data .select-list-data table td p {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  color: #757575;
  display: flex;
  align-items: center;
}
.modal-list-data .select-list-data table td p.list-adding {
  color:#3AB795;
}
.modal-list-data .select-list-data table td p.list-error {
  color:#DC3544;
}
.modal-list-data .select-list-data table td p span img {
  margin-right: 8px;
  max-width: 16px;
}
.modal-list-data .select-list-data table td:first-child p {
  font-weight: 700;
  color: #1a1a1a;
}
.select-list-loader {
  background-color: #fff;
}
.select-list-loader-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #E6E6E6;
}
.select-list-loader-row div {
  height: 5px;
            width: 70%;
            background: #E6EAF5;
            border-radius: 2px;
            margin:0 5%;
}
@media only screen and (max-width: 767px) {
  .modal-main {
    max-width: 100%;
    margin:20px;
    overflow: auto;
  }
  .modal-main .modal-header h2 {
    font-size: 20px;
  }
  .modal-list-name {
    width: 100%;
    display: block;
    text-align: center;
    margin: 0 0 20px;
  }
  .modal-list-name figure {
    width: 60px;
    margin:0 auto 10px;
  }
  .modal-list-name figure img {
    width: 60px;
    height: auto;
    max-width: 100%;
    max-height: inherit;
  }
  .modal-list-name h3 {
    font-size: 18px;
  }
  .modal-list-name h4 {
    font-size: 14px;
  }
  .modal-list-data .select-list {
    display: block;
    padding:0 15px 20px;
    text-align: center;
  }
  .select-list .modal-search {
    width: 100%;
  }
  .select-list .modal-search {
    height: 35px;
  }
  .select-list .modal-search input {
    height: 35px;
  }
  .select-list .modal-search {
    background: url(images/modal-search-icon.svg) no-repeat 10px 7px;
    margin-bottom: 15px;
  }
  .modal-list-data .select-list p {
    padding-bottom: 15px;
  }
  .modal-list-data .select-list-data-cont {
    padding: 0 15px;
  }
  .modal-main .modal-list-body {
    padding: 20px 20px;
  }
  .modal-list-data {
    margin-bottom: 20px;
  }
  .modal-main .modal-close {
    font-size: 14px;
    padding: 8px 20px;
  }
  .modal-list-data .select-list button span {
    display: block;
  }
  .modal-list-data .select-list button {
    font-size: 14px;
    line-height: 17px;
    padding: 7px 15px;
  }
}
/* Copy from  here */