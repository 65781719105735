body{
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    /* background-color: white; */
}

.logoClass{
    height: 52px!important;
    /* width: 56px!important; */
}

.custom_card:first-child {
    margin-top: 30px;
}

.TableHeads{
padding-top: 15px;
}

.custom_card{
    display: flex;
    flex-direction: column;
    margin: 10px 2em 30px 2em;
    box-shadow: 0 0 10px 10px #eee;
    padding:20px 0 0 0;
    border-radius:8px;
    background-color: white;
}
.row_c{
    display: flex;
    align-items: center;
    /* width: 100%; */
    box-sizing: border-box;
    padding-bottom: 14px;
}
.col_c{
    /* padding:5px; */
    margin-left: 5px;
    padding-bottom: 0px!important;
}
.col_d{
    /* padding:5px; */
    margin-left: 5px;
    padding-bottom: 0px!important;
}
.image_col img{
    height: 40px;
    width: 40px;
    border-radius: 50%;

}
.heading_col h4{
    font-size: 24px;
    text-decoration: underline;
    padding-left: 4px;
    font-weight: 700;
}
.data_row{
    justify-content: space-between;
    padding-left: 50px;
    overflow-x:no-scroll;
}
.data_row .col_c {
    width: 15%;
}
.data_row .col_d {
    width: 15%;
}
.data_row label{
    font-weight: 500;
    font-size: 14px;
    font-weight: bold;
    padding-top: 4px;
    height: 29px;
}
.data_row p {
    font-size: 14px;
}
.data_head{
    border-bottom: 1px solid #ccc;
    /* margin-left: 4px; */
    padding-bottom: 0px!important;
}
.badge_c {
    padding: 7px 12px;
    width: 105px;
    height: 29px;
    margin-top: 6px;
    padding-top: 4px;
    font-weight: 500;
    text-align: center;
    background: #eaeaea;
    margin-left: -12px;
    border-radius: 8px;
    text-transform: uppercase;
}
.row_c.data_row.data_content{
    padding-top: 10px;
    padding-bottom: 10px;
    /* margin-left: 3px; */
}
.row_c.data_row.data_content:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
}
.badge_c-success {
    background: #F0FBF7;
    color: #1E8A5E;
}
.badge_c-danger {
    background: #FEF1F2;
    color: #C02634;
}

.badge_c-light {
    background: #F8FAFF;
    color: #2D3958;
}

.data_row .col_c:nth-child(2),.data_row .col_c:nth-child(3) {
    min-width:19%;
}
.data_row .col_c:nth-child(4) {
    max-width:95px;
}
.data_row .col_d:nth-child(1),.data_row .col_d:nth-child(2) {
    min-width:1%;
}
/* .data_row .col_d:nth-child(4) {
    max-width:95px;
} */

.rotate-once-per-second {
    animation: rotateOncePerSecond 1s infinite linear;
  }
  
  @keyframes rotateOncePerSecond {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

.custom-svg {
    cursor: pointer;
  }

.custom-fill {
    transition: fill 0.3s ease;
    fill: #9CDBCA;
  }

  /* Change the fill color on hover */
  .custom-fill:hover {
    fill: #3AB795;
  }
  /* Apply the hover effect to the entire SVG */
svg:hover .custom-fill {
    fill: #3AB795;
  }

    /* Apply the hover effect to the entire SVG */
svg:hover .custom-fill {
    fill: #3AB795;
  }

  .custom-fills {
    transition: fill 0.3s ease;
    fill: #e5868d;
  }

  /* Change the fill color on hover */
  .custom-fills:hover {
    fill: #DC3544;
  }

/* Apply the hover effect to the entire SVG */
svg:hover .custom-fills {
    fill: #DC3544;
  }

@media screen and (max-width: 1024px) {
    body{font-size: 12px;}   
    .data_row{padding-left: 0px;}
    .data_row .col_c {
        min-width: 150px;
        /* width: 150px !important; */
    }
    .data_row .col_d {
        min-width: 150px;
        /* width: 150px !important; */
    }
    .data_row {
        max-width: 100%;
        width: 100%;
    }
    .data_row:not(:last-child){scrollbar-width: none;}
    .badge_c {
    padding: 3px 4px;
    margin-left: 0px !important;
    font-size: 10px;
    }

}