:root {
  --light-gray: #777a80;
}

body {
  /* font-family: Arial, sans-serif; */
  font-family: "DM Sans", sans-serif !important;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.popupContainer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Light black background */
  z-index: 999;
  overflow: auto; /* Allow scrolling within popup if needed */
}

.popupContent {
  background-color: #fff;
  width: 1198px; /* Total width of sidebar + content */
  max-width: calc(100% - 40px); /* Adjusted to fit within viewport */
  margin: 20px auto;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
}

.sidebar {
  width: 100%;
  max-width: 399px;
  /* height: 100%; */
  gap: 0px;
  border-radius: 8px 0px 0px 8px;
  opacity: 0.9; /* Changed opacity for visibility */
  background-color: #f7f9ff; /* Sidebar background color */
  filter: drop-shadow(2px 2px 8px rgb(0 0 0 / 0.05))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.sidebar-content {
  padding: 15px 15px 30px 15px;
}

.modal-list-names {
  /* width: 250px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}
.modal-list-names figure {
  width: 79px;
  height: 79px;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 18px;
}

.modal-list-names figure img {
  width: 79px;
  max-width: 79px;
  height: 79px;
  max-height: 79px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  object-fit: cover;
  object-position: top;
}
.modal-list-names h3 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0;
}

.modal-list-names h4 {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  color: #535353;
  margin: 0;
}

.content {
  flex-grow: 1;
  padding: 0 20px; /* Added padding for content area */
  width: 100%;
  max-width: 799px;
}

.content-head h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 23.44px;
}
.closePopup {
  display: inline-flex;
  content: "";
  background-image: url(./images/cross.svg);
  height: 40px;
  width: 40px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.closePopup:hover {
  color: red;
}

.notes {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  gap: 0px;
  margin-left: 20px;
}
.note-head {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  margin-top: 0px;
}
.note-p {
  color: var(--light-gray);
  font-size: 12px;
  margin-top: 2px;
  font-weight: 700;
}

.sidebar-describe {
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.sidebar-describe textarea {
  height: auto;
  min-height: 406px;
  width: 100%;
  max-width: 360px;
  border: 1px solid #e6eaf8;
  border-radius: 8px;
  font-family: "DM Sans";
  line-height: 16.73px;
  font-size: 14px;
  padding: 16px;
}
.sidebar-describe textarea::placeholder {
  /* font-family: "DM Sans"; */
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 14px!important;
  line-height: 16px!important;
  font-family: "DM Sans", sans-serif!important;
  color: #424d6a!important;
}

textarea:focus-visible {
  outline: none;
}

.content-head {
  text-align: center;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 0.5px solid #e6e6e6;
  background-color: #fbfbfb;
  border-radius: 8px;
  width: 100%;
  max-width: 626px;

  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
}

button.tablinks.tab2 {
  border-right: 0.5px solid #e6e6e6;
  border-left: 0.5px solid #e6e6e6;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 10px;
  transition: 0.3s;
  font-size: 16px;
  /* width: 208px; */
  width: 100%;
  font-weight: 500;
  font-family: "DM Sans";
  line-height: 20.83px;
  color: #434343;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #e6e6e6;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #d9d9d9;
  font-weight: 700;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tab-form {
  background: #f7f9ff;
  border-radius: 8px;
  border: 1px solid #e6eaf8;
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin-bottom: 25px;
  /* overflow: auto;
    max-height: 390px; */
}
.tabcontents {
  padding-left: 20px;
  padding-right: 20px;
}

.tabcontents h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;
  margin-top: 25px;
  margin-bottom: 18px;
}

/* form styling */
.form-control:focus {
  border-color: #e6e9f0 !important;
  box-shadow: none !important;
}

label.form-label {
  color: #182135;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.44px;
  font-family: "DM Sans";
}

::placeholder {
  /* color: #424D6A !important; */
  font-family: "DM Sans";
}
.btn-div {
  margin-bottom: 25px;
}

button.btn-cancel {
  border: 1px solid #ededed;
  border-radius: 8px;
  background: #f8faff;
  width: 87px;
  height: 39px;
  color: #434343;
  font-weight: 500;
}

button.btn-create{
  border: 1px solid #008cf0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  background: #0496ff;
  border-radius: 8px;
  width: 114px;
  height: 39px;
  font-weight: 500;
  color: #fff;
  position: relative;
  padding-left: 20px;
}

button.btn-creating{
  border: 1px solid #008cf0;
  background: #0496ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  width: 114px;
  height: 39px;
  font-weight: 500;
  color: #fff;
  position: relative;
  padding-left: 20px;
}

button.btn-save {
  border: 1px solid #008cf0;
  background: #0496ff;
  border-radius: 8px;
  width: 97px;
  height: 39px;
  font-weight: 500;
  color: #fff;
  position: relative;
  padding-left: 20px;
}

button.btn-save:before {
  display: inline-flex;
  content: "";
  background-image: url("./images/save-i.svg");
  height: 17px;
  width: 15px;
  position: absolute;
  left: 13px;
  top: 10px;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c5d2;
  border-radius: 8px;
}

.form-check-input:focus {
  border-color: #e6e9f0 !important;
  outline: 0;
  box-shadow: none !important;
}
.form-check-input {
  width: 30px !important;
  height: 30px !important;
  margin-top: 0px !important;
}
.form-check {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.file-upload {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 auto;
  margin-top: 30px;
  align-items: center;
  gap: 5px;
}
.btn-file {
  background-color: #f1f3fb;
  border-radius: 8px;
  border: none !important;
  width: 293px;
  height: 45px;
  position: relative;
}

.btn-files {
  background-color: #f1f3fb;
  border-radius: 8px;
  border: none !important;
  width: 293px;
  height: 45px;
  position: relative;
}

button.btn-file:before {
  display: inline-flex;
  content: "";
  background-image: url("./images/file-u.svg");
  height: 18px;
  width: 15px;
  position: absolute;
  left: 63px;
  top: 14px;
}

.experience-des textarea{
  height: auto;
  min-height: 854px !important;
}
.cand-des-div{
  margin-top: 0px !important;
}

.cand-des-div h3{
color: #182135;
font-size: 18px;
font-weight: 400;
line-height: 23.44px;
font-family: "DM Sans";
margin-bottom: 10px;
}

.cand-des-div p{
  font-size: 14px;
  font-weight: 700;
  color: #757575;
  line-height: 18.23px;
  
  }

  .prefernces-candidate-des textarea {
    width: 100%;
    max-width: 677px;
    border-color: #E6E9F0;
    border-radius: 8px;
    padding: 15px;
    /* min-height: 236px; */
    color: #424D6A;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.44px;
    font-family: 'DM Sans';
    opacity: 0.5;
}

.count-word {
  margin-top: 18px;
}
.count-word p{
  margin-bottom: 0px;
  color: #424D6A;
  font-size: 11px;
  font-weight: 500;
  line-height: 14.32px;
}
.content{
  position: relative;
}

/* .content::after{
  display: inline-flex;
  content: "";
  background-image: url(./images/cross.svg);
  height: 40px;
  width: 40px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
} */
.pref textarea {
  height: auto;
  min-height: 1065px;
}

.file-upload label{
  text-align: center;
}

.form-check-input:checked[type=checkbox]{
  background-image: url('./images/tick.svg') !important;
  background-size: 23px 23px !important;
}

.form-check-input:checked{
  background-color: transparent !important;
}

.form-check-input:checked {
  border-color: #0d6efd !important;
}

@media only screen and (max-width: 667px) {
  .popupContent {
    flex-wrap: wrap;
  }
  .tab button {
    font-size: 11px;
    line-height: inherit;
  }
  .tab button {
    font-size: 11px;
    line-height: inherit;
  }
  .btn-div {
    display: flex !important;
    justify-content: end;
  }
  .file-upload label {
    font-size: 11px;
    line-height: normal;
}
.content::after{
    top: 15px;
}
.content-head h4 {
    margin-top: 25px;
}
label.form-label {
    font-size: 15px;
}
.pref textarea,.sidebar-describe textarea,.experience-des textarea {
    min-height: 255px;
}

}

@media screen and (max-height: 900px) {
  .tabcontents {
    overflow: auto;
    max-height: 390px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 642px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 370px !important;
}
}

@media screen and (max-height: 950px) and (min-height: 900px) {
  .tabcontents {
    overflow: auto;
    max-height: 570px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 822px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 550px !important;
}
.buttonMargin{
  margin-top: 121px;
}
}

@media screen and (max-height: 1024px) and (min-height: 950px) {
  .tabcontents {
    overflow: auto;
    max-height: 620px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 872px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 600px !important;
}
.buttonMargin{
  margin-top: 160px;
}
}

@media screen and (max-height: 1144px) and (min-height: 1024px) {
  .tabcontents {
    overflow: auto;
    max-height: 620px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 872px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 600px !important;
}
.buttonMargin{
  margin-top: 163px;
}
}

@media screen and (max-height: 1250px) and (min-height: 1144px) {
  .tabcontents {
    overflow: auto;
    max-height: 740px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 992px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 720px !important;
}
.buttonMargin{
  margin-top: 283px;
}
}

@media screen and (max-height: 1350px) and (min-height: 1250px) {
  .tabcontents {
    overflow: auto;
    max-height: 940px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 1174px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 920px !important;
}
.buttonMargin{
  margin-top: 485px;
}
.tab-form{
  padding-bottom: 15px;
}
}

@media screen and (max-height: 1450px) and (min-height: 1350px) {
  .tabcontents {
    overflow: auto;
    max-height: 1020px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 1254px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 1000px !important;
}
.buttonMargin{
  margin-top: 559px;
}
.tab-form{
  padding-bottom: 15px;
}
}

@media screen and (max-height: 1550px) and (min-height: 1450px) {
  .tabcontents {
    overflow: auto;
    max-height: 1140px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 1374px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 1120px !important;
}
}

/* @media screen and (max-width: 1644px) {
  .tabcontents {
    overflow: auto;
    max-height: 696px;
    overflow-x: hidden;
  }
  .popupContent{
    height: 927px;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 696px !important;
}
}

@media screen and (max-width: 1500px) {
  .tabcontents {
    overflow: auto;
    max-height: 350px;
    overflow-x: hidden;
  }
  .experience-des textarea, .pref textarea, .sidebar-describe textarea {
    min-height: 325px !important;
}
} */


/* ipad mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab button {
    font-size: 11px;
    line-height: inherit;
    padding: 5px;
  }
  label.form-check-label {
    font-size: 12px;
}
.form-check{
    gap: 5px !important;
}
.sidebar{
    width: auto;
}
.modal-list-names h3 {
    font-size: 19px;
}
.modal-list-names h4 {
    font-size: 15px;
}
label.form-label {
    font-size: 14px;
}


.sidebar-describe textarea,.experience-des textarea,.pref textarea {
    min-height: 345px !important;
}

}