@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap');
* {
  padding: 0;
  margin: 0;
}

section {
  font-family: 'DM Sans', sans-serif;
}


.popupbox {
  width: 100%;
  height: 100%;
  position: relative;
  /* background-color: transparent;
  padding: 10px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupbox-innerr {
  max-width: 850px;
  width: 100%;
  /* border-radius: 1px; */
  background-color: transparent;
  overflow: hidden;
}

.popupbox-top {
  padding: 20px;
  background-color: #0496FF;
  text-align: center;
  border-radius: 8px 8px 0px 0px;
  position: relative;
}

.popupbox-top:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right:0;
  width: 0;
	height: 0;
  border-left: 860px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 21px solid #fff;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.popupbox-contentbox {
  display: flex;
  flex-direction: column;
}

.popupbox-top h2{
  font-size: 30px;
  font-weight: 700;
  line-height: 39.06px;
  margin-bottom: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.popupbox-top h2 span {
  margin-right: 30px;
}

.popupbox-body {
  padding: 25px 30px 30px 30px;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-top: -5.5px;
  /* overflow-y: auto; */
  /* height: 490px; */
}

/* @media screen and (min-width: 1880px) {
  .popupbox-body {
    padding-bottom: 60px;
  }
} */



.popupbox-body h3 {
  font-size: 26px;
  color: #3D424D;
  margin-bottom: 15px;
}

.popupbox-body p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: #3D424D;
}


@media (min-width: 1200px) {
  .popupbox-list {
    background-color: #EAEAEA;
    max-width: 80%;
    margin: auto;
    margin-top: -20px!important;
    padding-top: 25px;
    padding-right: 119px!important;
    padding-left: 70px!important;
    padding-bottom: 35px;
    border-radius: 0px 0px 8px 8px;
  }
}

@media only screen and (min-width: 990px) and (min-height: 990px) {
  .popupbox-list {
    background-color: #EAEAEA;
    max-width: 80%;
    margin: auto;
    margin-top: -20px!important;
    padding-top: 25px;
    padding-right: 130px!important;
    padding-left: 80px!important;
    padding-bottom: 35px;
    border-radius: 0px 0px 8px 8px;
  }
}

.popupbox-list {
  background-color: #EAEAEA;
  max-width: 80%;
  margin: auto;
  margin-top: -20px!important;
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 35px;
  border-radius: 0px 0px 8px 8px;
}

.popupbox-list label{
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    padding: 12px 12px 12px 0;
    display: inline-block;
}

.popupbox-list .textareacontent{
  text-align: left;
  padding-right: 8px;
}

.popupbox-list #textarea {
    width: 326px;
    height: 26px;
    padding-left: 12px;
    padding-top: 7px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    background-color: white;
    color: #3D424D;
    resize: vertical;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
}
.popupbox-list input[type=text], select {
    width: 326px;
    height: 26px;
    padding-left: 12px;
    /* padding-top: 10px; */
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    resize: vertical;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
  }
  .popupbox-list input:focus {
    border: none;
    outline: none; /* Optional: Remove the default focus outline */
  }
.popupbox-list .container {
    border-radius: 5px;
    width: 91%!important;
    /* background-color: #f2f2f2; */
    /* padding: 20px; */
  }  
  .popupbox-list .ConnectBtn{
    width: 112px!important;
    height: 38px!important;
    background-color: #0496FF!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 20.63px!important;
  }
  .popupbox-list .col-25 {
    float: left;
    width: 15%;
    margin-top: -12px;
  }
  .popupbox-list .col-75 {
    float: left;
    width: 75%;
    margin-top: -2px;
  }
  
 @media screen and (max-width: 600px) {
    .popupbox-list .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }


 .titlehead {
  max-width: 80%;
  margin: 20px auto;
  padding: 7px;
  border-radius: 8px 8px 0px 0px;
  font-size: 14px!important;
  line-height: 23px!important;
  font-weight: 700!important;
  color: #3D424D;
}

.popupbox-list ul li h3{
  font-size: 22px;
  margin-bottom: 0px;
  display: flex;
  align-items: flex-start;
}
.popupbox-list ul li h3 span {
  margin-right: 10px;
}

.popupbox-bottom {
  max-width: 92%;
  width: 100%;
  margin: 0px auto;
  padding-top: 10px;
}

.popupbox-bottom-inn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  margin: 20px 0;
}

.popupbox-bottom-box {
  padding: 25px;
  background-color: #fff;
  text-align: center;
}

.popupbox-bottom-box.w-thrid {
  width: 25%;
}
.popupbox-bottom-box.w-half {
  width: 50%;
  position: relative;
  
}

.popupbox-bottom-box.w-half:after {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  width: 1px;
  height: 80px;
  background-color: #EBEBEB;;
}

.popupbox-bottom-box.w-half:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 1px;
  height: 80px;
  background-color: #EBEBEB;;
}

.popupbox-bottom-box h2 {
  color: #3AB795;
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
}

.buynow {
  background-color: #6936FF;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}

.buynow:hover {
  background-color: #FFD166;
  color: #000;
}

.popupbox-copyright {
  text-align: center;
  padding: 0px 0p 25px 20px;
}

.popupbox-copyright p {
  font-style: italic;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.popupbox-copyright p a {
  color: #000;
  text-decoration: underline;
}

.popupbox-copyright p a:hover {
  color: #6936FF;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2); 
}