/*@tailwind base;*/
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Nanum+Gothic:wght@400;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*font-family: 'DM Sans', sans-serif;
font-family: 'Poppins', sans-serif;*/





/*start POPUP Design*/

@media(min-width: 768px) {
    .popup-premium .modal-dialog {
        max-width: 660px;
        padding-bottom: 45px;
    }
}

.popup-premium {
    z-index: 10000 !important;
}
.popup-premium .modal-backdrop {
    z-index: 0 !important;
    background-color: rgba(0, 0, 0, 0.75);
}
.popup-premium .modal-dialog {
    background: #fff;
    /* box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25) !important; */
    border-radius: 8px 8px 8px 8px !important; 
    overflow: hidden !important;
    position: relative;
    z-index: 9999 !important;
    margin-top: 155px;
}

@media only screen and (min-width: 990px) and (min-height: 990px) {
    .popup-premium .modal-dialog {
        display: flex;
        top: -2%;
        left: 24%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1200px) and (min-height: 990px) {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 25%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1200px) and (min-height: 1100px) {
    .popup-premium .modal-dialog {
        display: flex;
        top: 2%;
        left: 25%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1400px) and (min-height: 1100px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: 2%;
        left: 30%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1400px) and (min-height: 990px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 30%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1600px) and (min-height: 1100px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 31%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1600px) and (min-height: 990px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 31%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1800px) and (min-height: 1100px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 34%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 1800px) and (min-height: 990px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 34%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 2000px) and (min-height: 1100px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 37%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}

@media only screen and (min-width: 2000px) and (min-height: 990px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -5%;
        left: 37%;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-top: 130px;
    }
}




/* @media only screen and (min-width: 1600px) and (min-height: 1200px)  {
    .popup-premium .modal-dialog {
        display: flex;
        top: -9%;
        left: 35%;
        justify-content: center;
        align-items: center;
        position: fixed;
    }
} */

@media only screen and (min-width: 2000px) and (min-height: 1340px) {
    .popup-premium .modal-dialog {
        display: flex;
        top: -9%;
        left: 35%;
        justify-content: center;
        align-items: center;
        position: fixed;
    }
}

/* @media only screen and (min-width: 2000px) and (min-height: 1200px) {
    .popup-premium .modal-dialog {
        left: 35%;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .popup-premium .modal-dialog {
        left: 1%;
    }
} */

/* @media only screen and (min-width: 2000px) and (max-width: 3000px) {
    .popup-premium .modal-dialog {
        left: 37%;
    }
} */

.c-btn-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000 !important;
}
    .popup-premium .modal-header {
        top: 0;
        left: 0;
        right: 0;
    }
    .popup-premium .modal-header button.btn-close {
        width: 16px;
        height: 16px;
        text-align: center;
        background-color: transparent;
        cursor: pointer !important;

    }
        @media(max-width:575px) {
            .popup-premium .modal-header button.btn-close {
                right: 0; top: 0;
                width: 14px;
                height: 14px;
            }
        }
        .popup-premium .modal-header button.btn-close svg {
            fill: #fff;
        }

    .popup-premium .modal-header {
        padding: 0;
        z-index: 1;
    }



.popup-premium .modal-body {
    position: relative;
}


.popup-box {
    position: relative;
    overflow: hidden;
    background: #fff;
}

.popup-headerbox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
}
    .popup-header-inner {
        padding: 16px 20px 0;
        background-color: #6936FF;
        position: relative;
        z-index: 1;
    }
    .popup-header-inner:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        
    }
    .popup-headerbox h2 {
        margin-bottom: 0;
        margin-top: 15px;
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 47px;
        color: #fff;
    }
    @media(max-width: 1024px) {
        .popup-headerbox h2 {
            margin-top: 8px;
            font-size: 30px;
            line-height: 37px;
        }
    }
    @media(max-width: 667px) {
        .popup-headerbox h2 {
            font-size: 27px;
            line-height: 35px;
        }
    }
    @media(max-width: 575px) {
        .popup-headerbox h2 {
            font-size: 24px;
            line-height: 28px;
        }
    }
    @media(max-width: 480px) {
        .popup-headerbox h2 {
            font-size: 22px;
        }
    }
    .popup-title-icon {
        width: 75px;
        height: 75px;
        object-fit: cover;
        margin-right: 3rem;
        margin-bottom: 0;
        margin-left: -6.2rem;
    }
        @media(max-width: 1024px) {
            .popup-title-icon {
                margin-right: 1.5rem;
                margin-bottom: 0;
                margin-left: -4rem;
            }
        }
        @media(max-width: 768px) {
            .popup-title-icon {
                margin-right: 1rem;
            }
        }
        @media(max-width: 575px) {
            .popup-title-icon {
                margin-right: 1rem;
                margin-top: 4px;
            }
        }
        @media(max-width: 480px) {
            .popup-title-icon {
                margin-left: -2.5rem;
            }
        }
        @media(max-width: 360px) {
            .popup-title-icon {
                margin-right: 0.75rem;
                margin-left: -2rem;
            }
        }
        @media(max-width: 359px) {
            .popup-title-icon {
                margin-left: 0;
            }
        }

        .popup-title-icon img {
            width: 75px;
            height: 75px; 
            object-fit: cover;
        }
        @media(max-width: 1024px) {
            .popup-title-icon, .popup-title-icon img {
                width: 50px;
                height: 50px;
            }
        }
        @media(max-width: 768px) {
            .popup-title-icon, .popup-title-icon img {
                width: 50px;
                height: 50px;
            }
        }
        @media(max-width: 575px) {
            .popup-title-icon, .popup-title-icon img {
                width: 30px;
                height: 30px;
            }
        }

    .popup-headerbg {
        display: block;
        width: 100%;
        height: 40px;
        position: relative; z-index: 0;
        top: -28px; 
        background-color: #6936FF;
        transform: skewY(179deg);
    }



.popup-premium .modal-container {
    position: absolute;
    top: 100px;
    height: calc(100% - 56px);
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    z-index: 0;
}


.popup-bodybox { 
    padding: 45px 40px 40px; 
}
@media(max-width: 1024px) {
    .popup-bodybox { 
        padding-top: 30px; 
    }
}
@media(max-width: 767px) {
    .popup-bodybox { 
        padding-top: 20px; 
    }
}
.popup-box h3 {
    margin-bottom: 26px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #000;
}

.popup-body-content {
    margin-bottom: 34px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
    .popup-body-content h3 {
        margin-bottom: 8px;
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        color: #000;
    }
    .popup-body-content p {
        margin-bottom: 30px; 
        font-family: 'DM Sans', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #000;
    }
        .popup-body-content p:last-of-type {
            margin-bottom: 0;
        }
    .popup-body-content span {
        padding: 4px 6px;
        display: inline-block;
        vertical-align: middle;
        background: rgba(58, 183, 149, 0.05);
        border-radius: 4px;
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        text-align: right;
        text-transform: uppercase;
        color: #3AB795;
    }



.c-iconPic-row {
    margin-bottom: 2rem;
}

.c-iconPic-row h4 {
    margin: 0 0 2px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
.c-iconPic-row p {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000;
}

/*.c-iconPic-row img {
    height: 130px;
}*/

.c-iconPic-img {
    width: 150px;
    height: 74px;
    text-align: center;
    object-fit: cover;
    margin-top: 1rem;
}
    .c-iconPic-img img {
        width: 100%; 
        height: 74px; 
        object-fit: cover; 
    }


.plan-box {
    max-width: 640px;
    padding: 10px 20px;
    margin-bottom: 30px;
    background: #fff;
    box-shadow: 0 0 20px rgba(0,0,0, 0.1);
    border-radius: 4px;
}
    .plan-box .plan-details {
        border-top: #EBEBEB solid 1px;
        border-bottom: #EBEBEB solid 1px;
    }
        .plan-box .plan-details p {
            font-family: 'DM Sans', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #000;
        }
        @screen md {
            .plan-box .plan-details {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-top: none;
                border-bottom: none;
                border-left: #EBEBEB solid 1px;
                border-right: #EBEBEB solid 1px;
                border-radius: 1px;
            }
        }


    .plan-box {
        display: flex;
        flex-direction: row;
    }
    @media(max-width: 768px) {
        .plan-box {
            flex-direction: column;
        }
        .plan-box .plan-charge, .plan-box .plan-details, .plan-box .plan-btn{
            width: 100%;
        }

    }
    .plan-box .plan-charge {
        flex: 1 1;
        text-align: center;
    }
    .plan-box .plan-charge h3 {
        margin-bottom: 0;
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        color: #3AB795;
    }
        .plan-box .plan-charge h3 span {
            font-family: 'DM Sans', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #777;
        }

.btn-buynow-plan {
    width: 114px; height: 33px;
    display: inline-block;
    background: #6936FF;
    border-radius: 5px;
    padding: 4px 0; 
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #fff;
}
/*end POPUP Design*/







/*.modal {
    background-color: #fff;
}*/
.modal .modal-fixed-header {
        padding: 0;
        height: 70%;
    }
    /*.modal .modal-fixed-header .modal-header {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 0;
        }
        .modal .modal-fixed-header .modal-header {
            border-radius: 0 0 2px 2px;
            background-color: #fafafa;
            padding: 4px 6px;
            height: 56px;
            width: 100%;
        }
        .modal .modal-fixed-header .modal-container {
            position: absolute;
            top:56px;
            height: calc(100% - 56px);
            max-height: 100%;
            width: 100%;
            overflow-y: auto;
        }*/