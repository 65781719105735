.timelineHolder {
    margin-top: 18px;
    margin-bottom: 13px;
    position: relative;
    line-height: 28px;
    font-family: DM Sans, sans-serif;
}

.line {
    position: absolute;
    width: 100%;
    z-index: 0;
    top: 94px;
    border-width: 4px;
    border-radius: 7px;
    color: #DEDEDE;
}

.timeline {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.container {
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

.dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    z-index: 1;
}

.thin {
    width: 3px;
    height: 18px;
}

.big {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    font-size: 13px;
}

.date, .title {
    margin: 7px 0 4px 0;
    text-align: center;
}

.title {
    font-weight: 700;

}

.title.round {
    font-size: 16px;
    line-height: 16px;
}

.title.event {
    font-size: 13px;
    line-height: 13px;
}

.date {
    font-weight: 500;
    color: #777A80;
    font-size: 13px;
    line-height: 13px;
}

.containerRow {
    display: flex;
    margin-bottom: 8px;
}

.containerRow > div {
    margin-bottom: 6px;
}

.containerRowCell {
    display: flex;
    align-items: center;
    min-width: 120px;
}

.RowIcon {
    margin-right: 4px;
}

.equalColumn {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
}

@media screen and (max-width: 999px) {
    .hideMobile {
        display: none !important;
    }
}