.FullBody{
    overflow: hidden;
    background-color: white;
}

a,
a:hover {
  text-decoration: none;
}

.section-title {
  margin: 47px 0 34px;
}

.about-top-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 38px 0px 38px 0px;
  width: 63%;
  margin: 0 auto;
}

@media only screen and (max-width: 480px) and (min-width: 350px)  {
  .about-top-inner {
    display: block;
    /* justify-content: center;
    align-items: center;
    gap: 25px; */
    padding: 17px 0px 20px 0px;
    width: 63%;
    margin: 0 auto;
  }
  .imageTages img{
     width: 167px!important;
  }
}
@media only screen and (max-width: 350px) and (min-width: 300px)  {
  .about-top-inner {
    display: block;
    /* justify-content: center;
    align-items: center;
    gap: 25px; */
    padding: 17px 0px 20px 0px;
    width: 63%;
    margin: 0 auto;
  }
  .imageTages img{
     width: 120px!important;
  }
}
.about-top-head h2 {
color: #fff;
font-weight: 700;
font-size: 30px;
line-height: 39px;
}
.about-top-head p {
color: #fff;
font-weight: 400;
font-size: 22px;
line-height: 28px;
}

.section-titless {
  margin: 1px 0 34px;
}

@media only screen and (max-width: 1440px) and (min-width: 1256px)  {
  .section-title {
    margin: 47px -67px 34px;
  }
  .section-titless {
    margin: 47px -67px 34px;
  }
}

@media only screen and (max-width: 1256px) and (min-width: 1150px)  {
  .section-title {
    margin: 47px -105px 34px;
  }
  .section-titless {
    margin: 47px -105px 34px;
  }
}

@media only screen and (max-width: 1150px) and (min-width: 1000px)  {
  .section-title {
    margin: 47px -151px 34px;
  }
  .section-titless {
    margin: 47px -151px 34px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 500px)  {
  .section-title {
    margin: 47px -151px 34px;
  }
  .section-titless {
    margin: 47px -151px 34px;
  }
}

@media only screen and (max-width: 1829px) and (min-width: 1645px)  {
  .section-title {
    margin: 47px 68px 34px;
  }
  .section-titless {
    margin: 47px 68px 34px;
  }
}

@media only screen and (max-width: 2000px) and (min-width: 1829px)  {
  .section-title {
    margin: 47px 109px 34px;
  }
  .section-titless {
    margin: 47px 109px 34px;
  }
}

@media only screen and (max-width: 2300px) and (min-width: 2000px)  {
  .section-title {
    margin: 47px 128px 34px;
  }
  .section-titless {
    margin: 47px 128px 34px;
  }
}

@media only screen and (max-width: 2500px) and (min-width: 2300px)  {
  .section-title {
    margin: 47px 203px 34px;
  }
  .section-titless {
    margin: 47px 203px 34px;
  }
}

@media only screen and (max-width: 2600px) and (min-width: 2500px)  {
  .section-title {
    margin: 47px 203px 34px;
  }
  .section-titless {
    margin: 47px 203px 34px;
  }
}

@media only screen and (max-width: 2780px) and (min-width: 2600px)  {
  .sectionTitlte {
    margin: 47px 279px 34px;
    display: grid;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 3000px) and (min-width: 2780px)  {
  .sectionTitlte {
    margin: 47px 330px 34px;
    display: grid;
    justify-content: flex-start;
  }
}

.section-title h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #182135;
}

.section-titless h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #182135;
}

.forom-box {
  background: #f8faff;
  border-radius: 32px;
  padding: 38px 33px 32px 54px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.form-check-label {
  margin-bottom: 0;
}

/* .tab-list {
  margin-bottom: 41px;
} */

.tab-list ul {
  padding-left: 0;
  justify-content: center;
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
}

.tab-list ul li {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #182135;
  margin: 0 15px;
  padding: 0 23px 17px;
  /* border-bottom: 4px solid #e6e9f0; */
}

.tab-list ul li a {
  color: #182135;
  display: flex;
  pointer-events: none;
}

.tab-list ul li a span {
  font-size: 8px;
  color: #e6e9f0;
  margin-right: 9px;
}

/* .tab-list ul li.active {
  border-bottom: 4px solid #6a35ff;
} */

/* .tab-list ul li.active a span {
  color: #6936ff;
} */

.form-row,
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.col-md-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
}

label {
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #182135;
  margin-bottom: 7px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inputtext {
  background: #ffffff;
  border: 1px solid #e6e9f0;
  border-radius: 8px;
  height: 44px;
}

/* .textarea {
  border: 1px solid #e6e9f0;
  border-radius: 8px;
} */

.circle{
  position: absolute;
  /* top: 171%; */
      right: 104%;
      width: 100px; /* Adjust the size of the circle as needed */
      height: 100px; /* Adjust the size of the circle as needed */
      border-radius: 50%;
      background-color: #6A35FF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white; /* Adjust the color of the text as needed */
      font-size: 14px;
}

.circle img {
  width: 12px; /* Adjust the size of the image as needed */
      height: 12px; /* Adjust the size of the image as needed */
      /* margin-bottom: 10px; */
}


textarea::placeholder {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  font-family: "DM Sans", sans-serif;
  color: #424d6a;
}

::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgba(66, 77, 106, 0.5) !important;
  font-family: "DM Sans", sans-serif;
}

select option {}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.mb-3,
.my-3 {
  margin-bottom: 32px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-md-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.col-sm-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.col-gap-90 {
  column-gap: 90px;
}

.col-gap-21 {
  column-gap: 21px;
}

.flex1 {
  flex: 1;
}

.flex180 {
  flex-basis: 180px;
}

.flex137 {
  flex-basis: 137px;
}

.flex140 {
  flex-basis: 145px;
}

hr {
  border: 0.5px solid #777a80;
  margin: 18px 0;
}
/* 
.flex-wrap {
  flex-wrap: wrap;
} */

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.FooterImage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 69px;
  background-color: #E6EAF5;
  margin-top: 10px;
}

.FooterImage img{
  padding-top: 9px;
  padding-bottom: 5px;
}

.submit {
  background: #6936ff;
  border-radius: 8px;
  /* padding: 7px 54px; */
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  height: 43px;
  width: 154px;
  text-align: center;
  color: #ffffff;
  font-family: "DM Sans", sans-serif;
}

.back {
  background: rgba(105, 54, 255, 0.05);
  border-radius: 8px;
  /* padding: 7px 54px; */
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  height: 43px;
  width: 154px;
  text-align: center;
  color: #6936ff;
  font-family: "DM Sans", sans-serif;
}

.GenderMargin{
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 24px;
}

.check-group>*+* {
  margin-top: 0.75rem;
}

.check-group .checkbox {
  counter-increment: total;
}

.check-group input[type="checkbox"]:checked {
  counter-increment: checked;
}

.check-group__result {
  font-weight: bold;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.check-group__result:after {
  content: counter(checked) " / " counter(total);
  padding-left: 1ch;
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox__input {
  position: absolute;
  width: 1.375em;
  height: 1.375em;
  opacity: 0;
  cursor: pointer;
}

.checkbox__input:checked+.checkbox__icon .tick {
  stroke-dashoffset: 0;
}

.checkbox__icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  overflow: visible;
  background: #ffffff;
  border: 1px solid #e6e9f0;
  border-radius: 8px;
  stroke: transparent;
}

.checkbox__icon .tick {
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  transition: stroke-dashoffset 0.2s ease-out;
}

.checkbox__label {
  margin-left: 0.5em;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #424d6a;
}

.mb-23 {
  margin-bottom: 17px;
}

.d-sm-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

article {
  position: relative;
  margin: 5px;
  background: #ffffff;
  border: 1px solid #e6e9f0;
  border-radius: 8px;
  box-sizing: border-box;
}

article div {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #424d6a;
  transition: 0.5s ease;
  padding: 10px 45px;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid transparent;
}

article input {
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E) !important;
  background-repeat: no-repeat, repeat !important;
  background-position: right 0.7em top 50%, 0 0 !important;
  background-size: 0.65em auto, 100% !important;
}

article input[type="checkbox"]:checked~div {
  border: 2px solid #6936ff;
}

.mt-19 {
  margin-top: 25px;
}

.NedMargin{
  margin-bottom: 28px;
}

.mr-32 {
  margin-right: 41px;
}

.btn-gap {
  column-gap: 40px;
}

.titleBanner {
  padding-top: 36px;
  padding-bottom: 36px;
}

.titleBanner h1{
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #182135;
  margin: 0 0 16px;
}

.titleBanner p {
  font-weight: 400;
  font-size: 16.2717px;
  line-height: 21px;
  color: #000000;
  margin: 0;
}

.section-title h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #182135;
  margin: 0 0 16px;
}

.section-titless h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #182135;
  margin: 0 0 16px;
}

@media (min-width: 767px) {
    .section-title h1 {
        font-weight: 700;
        font-size: 30px;
        line-height: 39px;
        padding-left: 6.1rem;
        color: #182135;
        margin: 0 0 16px;
      }
      .section-title p {
        font-weight: 400;
        font-size: 16.2717px;
        line-height: 21px;
        color: #000000;
        padding-left: 6.1rem;
        margin: 0;
      }
      .section-titless h1 {
        font-weight: 700;
        font-size: 30px;
        line-height: 39px;
        padding-left: 6.1rem;
        color: #182135;
        margin: 0 0 16px;
      }
      .section-titless p {
        font-weight: 400;
        font-size: 16.2717px;
        line-height: 21px;
        color: #000000;
        padding-left: 6.1rem;
        margin: 0;
      }
}

.section-title p {
  font-weight: 400;
  font-size: 16.2717px;
  line-height: 21px;
  color: #000000;
  margin: 0;
}

.section-titless p {
  font-weight: 400;
  font-size: 16.2717px;
  line-height: 21px;
  color: #000000;
  margin: 0;
}

.form-paragraph {
  margin-top: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #424d6a;
}

.number-left {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  color: #424d6a;
}

.important-para .head {
  font-weight: 700;
  margin-top: 0;
}

.important-para p {
  font-size: 16px;
  line-height: 21px;
  color: #424d6a;
}

.button_outer {
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  background: rgba(105, 54, 255, 0.05);
  border-radius: 8px;
}

.btn_upload {
  padding: 9px 131px;
  font-weight: 500;
  padding-top: 15px;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #6936ff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
}

.btn_upload input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.btn_upload input span {
  margin-right: 7px;
}

.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
}

.file_uploading .btn_upload {
  display: none;
}

.processing_bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 30px;
  background: #83ccd3;
  transition: 3s;
}

.file_uploading .processing_bar {
  width: 100%;
}

.success_box {
  display: none;
  width: 50px;
  height: 50px;
  position: relative;
}

.success_box:before {
  content: "";
  display: block;
  width: 9px;
  height: 18px;
  border-bottom: 6px solid #fff;
  border-right: 6px solid #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 17px;
  top: 10px;
}

.file_uploaded .success_box {
  display: inline-block;
}

.file_uploaded {
  margin-top: 0;
  width: 50px;
  background: #83ccd3;
  height: 50px;
}

.uploaded_file_view {
  max-width: 300px;
  margin: 40px auto;
  text-align: center;
  position: relative;
  transition: 0.2s;
  opacity: 0;
  border: 2px solid #ddd;
  padding: 15px;
}

.file_remove {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  position: absolute;
  background: #aaa;
  line-height: 30px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  right: -15px;
  top: -15px;
}

.file_remove:hover {
  background: #222;
  transition: 0.2s;
}

.uploaded_file_view img {
  max-width: 100%;
}

.uploaded_file_view.show {
  opacity: 1;
}

.banners{
  background: #E6EAF5;
  padding: 0 0 23px;
  position: relative;
}

.banners h4{
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #182135;
  padding-bottom: 10px;
  margin: 0;
}

.banner {
  background: #e1d7ff;
  padding: 31px 0 20px;
  position: relative;
}

.banner h4 {
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  padding-bottom: 10px;
  color: #182135;
  margin: 0;
}

.health-list {
  padding-left: 0;
  margin-top: 14px;
  margin-bottom: 0;
}

.health-list li {
  display: inline-block;
  margin-right: 8px;
  text-align: center;
}

.health-list li p {
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 0;
  text-align: center;
  margin-left: 4px;
  margin-top: 5px;
  color: #182135;
}

.img-fluids {
    max-width: 100%;
    height: auto;
    border: 1px solid white;
    border-radius: 50%;
    background-color: white;
  }

/* .image-placeholders{ */
    /* top: 0;
    left: 0;  */
    /* width: 253px;  */
    /* Adjust the width and height as per your requirements */
    /* position: relative;
    background: transparent;
    height: 218px;
    background-color: transparent; */
     /* Optional: Set a background color for the placeholder */
     /* Replace with the path to your placeholder image */
    /* background-size: cover;
    background-position: center;
    transition: opacity 0.3s ease-in-out; */
  /* }   */

.img-fluid {
  max-width: 100%;
  height: auto;
  /* opacity: 0; */
   /* Initially hide the actual image */
  /* transition: opacity 0.3s ease-in-out;
  animation: fadeIn 5s; */
}
/* 
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
} */

/* .img-fluid.visible {
  opacity: 1; /* Show the actual image when the 'visible' class is added */
/* } */

/* .img-fluid.hidden { */
  /* display: none; */
   /* Hide the actual image when the 'hidden' class is added */
/* } */ 

.banner-icon {
  width: 59px;
  height: 59px;
  text-align: center;
  margin: 0 auto;
}

.imagePosition{
  position: relative;
  display: inline-block;
}

.overlayImage{
position: absolute;
opacity: 1.5;
top: 0;
  left: 0;
  width: 31px;
  height: 31px;
  padding: 14px;
  /* color: white; */
}

.overlayImages{
  position: absolute;
  opacity: 1.5;
  top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    padding: 14px;
    /* color: white; */
  }

.banner-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-div {
  width: 660px;
}

.banner-img {
  position: absolute;
  top: -29%;
  right: 8%;
}

@media (max-width: 767px) {
  .health-list li {
    width: 28%;
  }

  .banner h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .banners h4 {
    font-size: 18px;
    line-height: 27px;
  }

  .banner {
    padding: 20px 0 20px;
    text-align: center;
  }

  .banners {
    padding: 20px 0 20px;
    text-align: center;
  }

  .section-title {
    text-align: center;
  }

  .section-titless {
    text-align: center;
  }

  .banner-imgs {
    margin-top: 0;
  }



  .banner-flex {
    margin: 0 15px !important;
  }

  .banner-flexx {
    margin: 0 15px !important;
  }

  .col-md-6 {
    max-width: 100%;
    flex: 100%;
  }

  .section-title h1 {
    font-size: 22px;
    line-height: 38px;
    margin: 0 0 10px;
  }

  .section-title p {
    font-size: 13.2717px;
    line-height: 21px;
  }

  .section-titless h1 {
    font-size: 22px;
    line-height: 38px;
    margin: 0 0 10px;
  }

  .section-titless p {
    font-size: 13.2717px;
    line-height: 21px;
  }

  .form-div {
    width: 100%;
    margin: 15px;
  }

  .tab-list ul li {
    display: block;
    margin-bottom: 17px;
  }

  .tab-list ul {
    display: block;
  }

  .forom-box {
    padding: 20px;
    margin-bottom: 27px;
  }

  label,
  .checkbox__label,
  article div {
    font-size: 16px;
    line-height: 21px;
  }

  ::placeholder,
  textarea::placeholder {
    font-size: 14px;
    line-height: 20px;
  }

  .col-md-8 {
    max-width: 100%;
    flex: 1;
  }

  .mb-3,
  .my-3 {
    margin-bottom: 16px;
  }

  .back {
    margin: 0 0 12px;
  }

  .form-paragraph {
    font-size: 14px;
    line-height: 19px;
  }

  .button_outer {
    display: block;
  }

  .btn_upload {
    padding: 9px 41px;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .d-sm-flex {
    display: block !important;
  }
}

select.inputtext {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #424d6a;
  font-family: "DM Sans", sans-serif;
}


.banner-flex {
  column-gap: 17px;
  margin-left: 12%;
}

.banner-flexxx {
  column-gap: 17px;
  margin-left: -5%;
}

@media only screen and (max-width: 1540px) and (min-width: 1200px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -7%;
  }
}

@media only screen and (max-width: 1368px) and (min-width: 1200px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -9%;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1100px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -11%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 1000px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -15%;
  }
}

@media only screen and (max-width: 2800px) and (min-width: 2200px)  {
  .bannerLeft {
    margin-left: -3%!important;
  }
}
@media only screen and (max-width: 2200px) and (min-width: 1800px)  {
  .bannerLeft {
    margin-left: -4%!important;
  }
}
@media only screen and (max-width: 1540px) and (min-width: 1300px)  {
  .bannerLeft {
    margin-left: -5%!important;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1200px)  {
  .bannerLeft {
    margin-left: -6%!important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1100px)  {
  .bannerLeft {
    margin-left: -7%!important;
  }
}
@media only screen and (max-width: 1100px) and (min-width: 800px)  {
  .bannerLeft {
    margin-left: -7%!important;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 850px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -17%;
  }
}

@media only screen and (max-width: 850px) and (min-width: 780px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -12%;
  }
}

@media only screen and (max-width: 1850px) and (min-width: 1700px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -4%;
  }
}

@media only screen and (max-width: 1950px) and (min-width: 1850px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -2%;
  }
}

@media only screen and (max-width: 2100px) and (min-width: 1950px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -3%;
  }
}

@media only screen and (max-width: 2300px) and (min-width: 2200px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -7%;
  }
}

@media only screen and (max-width: 2400px) and (min-width: 2300px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -2%;
  }
}

@media only screen and (max-width: 2800px) and (min-width: 2600px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -4%;
  }
}

@media only screen and (max-width: 3000px) and (min-width: 2800px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: -3%;
  }
}

@media only screen and (max-width: 3300px) and (min-width: 3000px)  {
  .banner-flexxx {
    column-gap: 17px;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 2100px) and (min-width: 2000px)  {
  .banner-flex {
    column-gap: 17px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 2200px) and (min-width: 2100px)  {
  .banner-flex {
    column-gap: 17px;
    margin-left: 7%;
  }
}

@media only screen and (max-width: 2300px) and (min-width: 2200px)  {
  .banner-flex {
    column-gap: 17px;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 2400px) and (min-width: 2300px)  {
  .banner-flex {
    column-gap: 17px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 3000px) and (min-width: 2400px)  {
  .banner-flex {
    column-gap: 17px;
    margin-left: 6%;
  }
}

.banner-flexs {
  column-gap: 17px;
}

.banner-content {
  flex-basis: 502px;
  /* max-width: 486px; */
}

.banner-contents {
  flex-basis: 582px;
  max-width: 600px;
}

.banner-imgs {
  margin-top: -8%;
}