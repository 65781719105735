/* * {
    padding: 0;
    margin: 0;
} */

ul {
    list-style: none;
}

a,
button {
    color: inherit;
}

a {
    text-decoration: none;
}

button {
    background: none;
    border: none;
    font-size: inherit;
    cursor: pointer;
    outline: none;
}

body {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
}

h1 {
    font-size: 1.2rem;
}

/*-------------- TAKE CSS START FROM HERE ------------------*/
.arrow {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 30px;
    max-width: 200px;
    display: flex;
}

.arrow img {
    margin-right: 10px;
    display: inline-block;
}

.AddPool{
    font-weight: 500;
    line-height: 18.23px;
    size: 14px;
    color: #3AB795;
    text-decoration: underline;
    margin-left: 15px;
    cursor: pointer;
}

.col65 {
    width: 65%;
    padding: 20px;
    border-radius: 8px;
    margin-left: 20px;
    box-sizing: border-box;
    background: #fff;
}
@media screen and (min-width: 768px) {
    .col65 {
        padding: 31px 36px;
    }
}

.col33 .col30 {
    /* width: 30%; */
    border-radius: 8px;
    border-top: 20px solid #E56399;
    background: #fff;
}

.saveColor{
    color: #2DB37C;
}

.deleteColor{
    color: #DC3544;
}

.boxText{
    background-color: #F8FAFF;
}

.notes-box-container {
    background-color: #f8faff;
    padding: 8px;
    /* padding-left: 14px; */
    padding-right: 14px;
    border-radius: 8px;
    /* margin-bottom: 12px; */
    padding-bottom: 6px;
    /* max-width: 400px; */
    margin: 0 auto;
    margin-bottom: 14px;
  }
  
  .notes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-top: 4px;
  }

  .delete-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  
  .notes-title {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #6F6F6F;
    /* font-size: 20px;   */
  }

  .notes-textareaa{
    width: 100%;
    /* height: 150px; */
    padding: 10px;
    /* border: 1px solid #ccc; */
    background-color: #F8FAFF;
    border-radius: 8px;
    resize: vertical;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    transition: border-color 0.3s; /* Add a transition for smooth effect */
  }
  
  .notes-textarea {
    width: 100%;
    /* height: 150px; */
    padding: 10px;
    /* border: 1px solid #ccc; */
    background-color: white;
    border-radius: 8px;
    resize: vertical;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    transition: border-color 0.3s; /* Add a transition for smooth effect */
  }

  .notes-textarea::placeholder {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #999; /* Placeholder text color */
  }

  .notes-textarea:focus {
    border-color: blue; /* Change border color on focus */
    outline: none; /* Remove the default blue outline */
  }
  
  .word-count {
    font-size: 11px;
    color: #888;
    /* margin-top: 5px; */
    text-align: right;
  }

  .dialog-box {
    /* position: absolute;
    top: 0;
    left: 0; */
    background-color: white;
    /* border: 1px solid #ccc; */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 6px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  }

  .dialog-box p {
    font-weight: 500;
  }

  .dialog-yes-button{
    background-color: #6936FF; 
    color: white;
    border-radius: 6px;
  }

  .dialog-no-button{
    background-color: rgba(105, 54, 255, 0.05);
    color: #6936FF;
    border-radius: 6px;
  }

  .dialog-yes-button,
  .dialog-no-button {
    margin-top: 10px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

.col34{
    border-radius: 8px;
    border-top: 20px solid #0496FF;
    margin-bottom: 30px;
    background: #fff;
}

.col35{
    border-radius: 8px;
    border-top: 20px solid #3AB795;
    margin-bottom: 30px;
    background: #fff;
}

.col33{
    width: 30%;
}

@media (max-width: 767px)  {
    .col33{
        width: 100%;
    }
}

.flexed {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.top-part {
    padding-bottom: 26px
}

.top-part h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #1A1A1A;
    margin-bottom: 4px
}

.top-part p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    max-width: 30rem;
    color: #535353;
    opacity: 1;
}

.top-part p b {
    font-weight: bold;
}

.top-part ul {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin: 10px 0 0;
}

.top-part ul li {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    margin-bottom: 10px;
}

.bussiness{
    max-width: 120px;
    text-align: center;
}

.test {
    display: flex;
    flex-direction: column;
    gap: 8px!important;
    align-items: center;
}

.marGin{
    margin-top: 1px;
}

.profile-info {
    gap: 20px
}

.profile-info .imagess img {
    width: 107px;
    height: 107px;
    border-radius: 8px;
    object-fit: cover;
}

.side-wrap {
    padding: 3px 20px;
    box-sizing: border-box;
}

.btnss{
    background: rgba(105, 54, 255, 0.05);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    width: 110px;
    padding: 8.5px;
    text-align: center;
    color: #9c9c9c;
    pointer-events: none;
    display: block;
    box-sizing: border-box;
}

.btn {
    background: rgba(105, 54, 255, 0.05);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    width: 110px;
    padding: 8.5px;
    text-align: center;
    color: #434343;
    display: block;
    box-sizing: border-box;
}

.btn.dark {
    background: #0496FF;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 6px;
}

.btn.darks {
    background: rgba(105, 54, 255, 0.05);
    color: #9c9c9c;
    pointer-events: none;
    display: flex;
    justify-content: center;
}

.btn.light {
    color: #6936FF;
    display: flex;
    justify-content: center;
}

.get-profile {
    display: flex;
    gap: 18px;
    justify-content: center;
    align-items: baseline;
}

@media screen and (max-width: 1170px) and (min-width: 1024px) {
    .get-profile {
        margin-top: 25px
    }
}

@media screen and (max-width: 1305px) and (min-width: 1024px) {
    .profile-info .image img {
        margin-bottom: 20px;
    }
}

.get-speciality{
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap; /* Allow bubbles to wrap onto the next line */
    gap: 2px; /* Adjust the gap between bubbles as needed */
}

@media screen and (max-width: 1114px) and (min-width: 800px) {
    /* .get-speciality {
        display: grid;
    } */
    .specialityBtn{
        margin-top: 10px;
    }
}
/* .bottom-part {
    border-top: 1px solid #E6E6E6;
    padding-top: 40px
} */

.bottom-parts h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1F1E22;
    margin-bottom: 15px
}

.bottom-parts p {
    font-style: normal;
    font-weight: 400;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 23px;
    color: #3D424D;
    margin-bottom: 20px
}

.bottom-parts .tag li {
    display: inline-block;
    margin-right: 15px;
}

@media screen and (max-width: 362px) and (min-width: 250px) {
    .bottom-parts .tag li {
        margin-bottom: 5px;
        margin-right: 2px;
    }
}

.side-wrap h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #1F1E22;
    padding-top: 14px;
    margin-bottom: 20px
}

.part {
    margin-bottom: 5px
}

.part:last-child {
    margin-bottom: 0px
}

.part h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #777A80;
    margin-bottom: 10px
}

.part ul li {
    display: flex;
    align-items: center;
    font-weight: 700;
    vertical-align: middle!important;
    margin-right: 1em;
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1F1E22;
}

.part ul li img {
    margin-right: 7px;
    vertical-align: middle;
}

.part ul.color li {
    padding: 7px 9px;
    border-radius: 8px;
    display: inline-flex;
    font-weight: 700;
}

@media (max-width: 1024px) {
    .col65 {
        width: 65%
    }

    .col30 {
        width: 100%
    }

    .get-profile {
        margin-top: 25px
    }
}

.get-speciality{
    margin-top: -16px;
}

.specialityBtn{
background-color: #BEF0DC;
cursor: default;
color: #2D3958;
font-weight: 500;
line-height: 15.62px;
padding: 6px 8px 6px 8px;
border-radius: 8px;
margin-right: 18px;
white-space: nowrap;
}

.bottom-parts{
    border-bottom: 1px solid transparent!important;
    padding-bottom: 20px!important;
}

.Compensation{
    background-color: #F8FAFF;
    padding: 15px 15px 15px 15px;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
}

.copDetails{
    color: #3D424D;
}

.compArray{
    color: #3D424D;
    font-weight: 500;
    margin-right: 15px;
}

.compenSpan{
    color: #6D6D6D;
    margin-left: 5px;
    font-weight: 700;
}

@media (max-width: 767px) {

    .col65,
    .col30 {
        width: 100%;
        margin: 15px 0
    }
}

/*-------------- TAKE CSS ENDS FROM HERE ------------------*/